import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { useStateValue } from '../../context/state'

import { Header, Card } from '../../components'
import { Button, Content, TextInput, Icon, Toast, Avatar, Loader } from '@checkplant/soil'
import { getUser, updateUser, passwordConfirm } from '../../services/user';

import { isEmpty } from '../../utils';

import AccessPrompt from './AccessPrompt';
import EditAccess from './EditAccess';

const CardMain = styled(Card)`
  max-width: 600px;
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  padding: 32px;
  transform: none;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.1),
    0px 30px 80px rgba(0, 0, 0, 0.06);

  @media only screen and (max-width: 599px){
    box-shadow: none;
  }
`;

const SubCard = styled.div`
  background: #FFF;
  box-shadow: 0px 2px 1px 0px rgba(65, 81, 100, 0.15);
  -moz-box-shadow: 0px 2px 1px 0px rgba(65, 81, 100, 0.15);
  -webkit-box-shadow: 0px 2px 1px 0px rgba(65, 81, 100, 0.15);
  border-radius: 8px;
  border: 1px solid #E7E8EF;
  padding: 16px;
`;

const MainTitle = styled.h2`
  margin-top: 30px;
  text-align: center;
  font-size: 22px;
  color: #415164;
`;

const Title = styled.h3`
  font-size: 18px;
  color: #627389;
  margin: 0px 0px 16px;
`;

const SubTitle = styled.span`
  color: #415164;
  margin-bottom: 24px;
  display: flex;
`;

const Edit = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState({});
  const [accessState, setAccessState] = useState('default');
  const [wrongPassword, setWrongPassword] = useState(false);
  const [errors, setErrors] = useState([]);
  const [updatedMessage, setUpdatedMessage] = useState('');
  const [{ currentUser }] = useStateValue();
  const [avatarImage, setAvatarImage] = useState({file: ''});

  const containerDiv = useRef();

  useEffect(() => {
    if (urlParams.get('uuid')) {
      getUser(urlParams.get('uuid'))
      .then((res) => {
        setUser(res.data);
        setAccessState('default');
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setErrors(['Houve um erro ao carregar as informações deste usuário']);
        setIsLoading(false);
      });
    } else {
      setUser(currentUser);
      setAccessState('default');
      setIsLoading(false);
    }
  }, [])

  const onChangeForm = e => {
    e.preventDefault();

    setUser({ ...user, [e.target.name]: e.target.value })
  };

  const save = () => {
    setIsLoading(true);
    let params = {
      name: user.name,
      username: user.username.toLowerCase(),
      email: user.email,
      phone: user.phone
    };

    if (user.password != "") {
      params = { ...params, password: user.password, password_confirmation: user.passwordConfirmation }
    }

    if (avatarImage && avatarImage.file != '') {
      params = { ...params, avatar: user.avatar }
    }

    updateUser(user.uuid, { user: params })
    .then((res) => {
      setIsLoading(false);
      setUpdatedMessage('Atualizado com sucesso!');
      setAccessState('default');
      setErrors([]);
      setUser({ ...user, password: null, passwordConfirmation: null });

      if (urlParams.get('return_to')) {
        window.location.href = urlParams.get('return_to');
      }
    })
    .catch((error) => {
      setIsLoading(false);
      setErrors(error.response.data);
      setUpdatedMessage('');
    })
  };

  const updateUserFromAccess = (params) => {
    setUser({ ...user, ...params })
  };

  const onDisplayAccess = (status) => {
    setWrongPassword(false);
    setErrors([]);
    setAccessState(status);
  };

  const unlock = (password) => {
    setWrongPassword(false);

    passwordConfirm(user.uuid, { password })
    .then((res) => {
      setAccessState('edit');
    })
    .catch((res) => {
      setWrongPassword(true);
    })
  };

  const setChangeFile = (e) => {
    if (e.target.files[0] != null) 
      setAvatarImage({file: URL.createObjectURL(e.target.files[0])})

    let key = e.target.name;

    let reader = new FileReader();
    reader.onloadend = function () {
      var b64 = reader.result.replace(/^data:.+;base64,/, '');
      setUser({ ...user, [key]: b64 })
    }
    reader.readAsDataURL(e.target.files[0]);
  }

  return (
    <>
      <Loader isLoading={isLoading} />

      <Header
        from={urlParams.get('return_to')}
        provider={'Farmbox'}
        iconProvider='default' />
      <Content>
        <MainTitle>Gerenciar conta</MainTitle>
        <CardMain>
          <div className="row">
            <div className="col-sm-12 col-mb-12">
             <Toast
              refValue={containerDiv}
              errors={errors}
              icon="fa-times"
              style={{position: "initial", marginBottom: 10}}
            />

              {updatedMessage && (
                <Toast
                  refValue={containerDiv}
                  errors={[updatedMessage]}
                  type="success"
                  icon="fa-check"
                  style={{position: "initial", marginBottom: 10}}
                />
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 col-mb-24">
              <Title><Icon name="user" style={{ marginRight: '8px' }} />Informações Pessoais</Title>
              <SubTitle>Informações básicas, como seu nome e foto, usadas no Farmbox.</SubTitle>

              <div className="avatar-file-container">
                <span className="flex title" style={{marginBottom: '4px', fontWeight: '700', fontSize: '12px'}}>Foto de Exibição</span>
                <label htmlFor="avatar-img" className="flex align-items-center">
                  <Avatar text={user.initials} size="55" img={avatarImage.file || user.avatar_url} />
                  <Button className="avatar-button btn btn-secondary ml-16" theme="regular">Adicionar</Button>
                  <input id="avatar-img" name="avatar" type="file" onChange={setChangeFile} style={{ display: 'none' }} accept="image/*" />
                </label>
              </div>

              <TextInput
                name="name"
                label="Nome de exibição"
                isDisabled={isEmpty(user)}
                value={user.name || ''}
                onChange={onChangeForm}
                type="text"
                style={{ marginTop: '16px', width: '190px', fontSize: '12px' }} />
              <TextInput
                name="username"
                label="Usuário"
                isDisabled={isEmpty(user)}
                value={user.username ? user.username.toLowerCase() : ''}
                type="text"
                style={{ marginTop: '16px', width: '190px', fontSize: '12px' }}
                onChange={onChangeForm} />
              <TextInput
                name="email"
                label="Email"
                isDisabled={isEmpty(user)}
                value={user.email || ''}
                type="email"
                style={{ marginTop: '16px', width: '190px', fontSize: '12px' }}
                onChange={onChangeForm} />
              <TextInput
                name="phone"
                label="Telefone"
                isDisabled={isEmpty(user)}
                onChange={onChangeForm}
                value={user.phone || ''}
                type="text"
                style={{ marginTop: '16px', width: '138px', fontSize: '12px' }} />
            </div>
            <div className="col-sm-6 col-mb-24">
              <Title><Icon name='lock' style={{ marginRight: '8px' }} />Acesso e Segurança</Title>
              <SubTitle>Configurações para ajudar você a manter sua conta segura.</SubTitle>
              <SubCard>
                { accessState == 'default' ? (
                  <>
                    <div className="mb-16">
                      <span className="font-soil label block">Senha</span>
                      <span className="font-soil information block"><i className="fa fa-key" style={{ color: '#798AA4'}}></i> *******</span>
                    </div>
                    <div className="flex justify-content-center">
                      <Button
                        theme="minor"
                        children="Editar"
                        disabled={isEmpty(user)}
                        rightIcon="fa-pencil"
                        onClick={() => onDisplayAccess("confirm")} />
                    </div>
                  </>
                ) : accessState == 'confirm' ? (
                  <AccessPrompt
                    errors={wrongPassword}
                    onSubmit={unlock}
                    onCancel={() => onDisplayAccess('default')} />
                ) : accessState == 'edit' ? (
                  <EditAccess
                    user={user}
                    errors={errors}
                    onUpdate={updateUserFromAccess} />
                ) : null}
              </SubCard>
            </div>
          </div>
          <div className="row">
            <div className="flex ml-16 mr-16" style={{ gap: 8 }}>
              <Button children="Salvar" theme="primary" disabled={isEmpty(user)} onClick={save} />
              <Button theme="outline" onClick={() => urlParams.get('return_to')} children="Cancelar" />
            </div>
          </div>
        </CardMain>
      </Content>
    </>
  )
}

export default Edit;
