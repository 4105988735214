import React, { useEffect, useState } from 'react'

import { useStateValue } from './context/state';
import { CHANGE_USER } from './context/types';
import { BrowserRouter, Switch } from "react-router-dom";
import { getCurrentUser } from './services/user';

import { Loader } from '@checkplant/soil';

import Routes from './Routes';

const Main = () => {
  const [state, dispatch] = useStateValue();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function loadData() {
      const currentUser = await getCurrentUser();
      const user = currentUser.data;

      dispatch({
        type: CHANGE_USER,
        payload: user,
      });

      setIsLoading(false);
    }

    loadData();
  }, []);

  if (isLoading) {
    return (
      <Loader isLoading={isLoading} />
    );
  }

  return (
    <>
      <BrowserRouter>
        <Switch>
          <Routes />
        </Switch>
      </BrowserRouter>
    </>
  )
}

export default Main;