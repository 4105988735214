import api from '../configs/api';
import qs from 'qs';

export const login = (params) => (
  api.post('users/sign_in.json', params)
);

export const signUp = (params) => (
  api.post('/users.json', params)
);

export const getCurrentUser = () => (
  api.get('users/me.json')
);

export const getUser = (uuid) => (
  api.get(`/users/${uuid}.json`)
);

export const updateUser = (uuid, params) => (
  api.put(`/users/${uuid}.json`, params)
);

export const passwordConfirm = (uuid, params) => (
  api.post(`/users/${uuid}/confirm.json`, params)
);

export const requestPassword = (params) => (
  api.post('users/password.json', params)
);

export const sendNewPassword = (params) => (
  api.put('users/password.json', params)
)