import React from 'react';

import SVGIcon from '../../services/SVGIcon';
import { Footer, Card, Logo } from '../../components';
import { Toast, Button, Content } from '@checkplant/soil';

const Recovered = () => {
  // TODO: Move this into a more configurable form
  const backNavigation = () => window.location.href = 'https://farmbox.cc';

  return (
    <>
      <Content>
        <Card>
          <Logo>
              <SVGIcon
                width="149"
                height="48"
                name={'farmbox-logo-with-writes'}
                fill={'none'}
              />
          </Logo>
          <Toast
            errors={['Nova senha cadastrada com sucesso']}
            type='success'
            icon='fa-check'
            style={{ position: 'initial', height: '100%' }}
            disableTimeout
          />

          <div className="flex justify-content-center mt-30">
            <Button theme="primary" children="Acessar o Farmbox" onClick={backNavigation} />
          </div>
        </Card>
      </Content>
      <Footer>
        <SVGIcon name={'farmbox-logo-footer'} fill={'none'} width="80" height="53" />
      </Footer>
    </>
  )
}

export default Recovered;
