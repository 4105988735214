import React from 'react'
import { useStateValue } from '../../context/state'
import Page403 from '../../screens/error/403';

const Admin = (props) => {
  const [{ currentUser }] = useStateValue();
  const { component } = props;

  if (currentUser.is_super_admin) {
    return component;
  }

  return <Page403 />;
}

export default Admin;