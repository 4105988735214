import React, { useState, useEffect } from 'react';

import { Modal, Toast, TextInput, Avatar, Button, Section } from '@checkplant/soil';

const EditModal = ({
  showModal, setClose, refValue, toastErrors, editClient, messageType, client, onClose
}) => {
  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [logo, setLogo] = useState('');
  const [state, setState] = useState('');
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');

  useEffect(() => {
    setName(client.name);
    setCity(client.city);
    setState(client.state);
    setPhone(client.phone);
    setCountry(client.country);
    setLatitude(client.latitude);
    setLongitude(client.longitude);
  }, [client])

  const onKeyDown = keyEvent => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  const setChangeFile = (e) => {
    let { files } = e.target;

    let reader = new FileReader();
    reader.onloadend = function () {
      setLogo(reader.result)
    }
    reader.readAsDataURL(files[0]);
  }

  const clearForm = () => {
    setName('');
    setCity('');
    setLogo('');
    setState('');
    setPhone('');
    setCountry('');
    setLatitude('');
    setLongitude('');

    onClose();
  }

  const onSubmit = e => {
    e.preventDefault();

    var newClient = {
      name, city, state, country, phone, latitude, longitude
    }

    if (logo && logo != '') {
      var b64 = logo.replace(/^data:.+;base64,/, '');

      newClient = { ...newClient, logo: b64 }
    }

    editClient(newClient, client.uuid)
  }

  return (
    <form onKeyDown={onKeyDown} onSubmit={onSubmit}>
      <Modal
        showModal={showModal}
        onClose={() => {setClose(false); clearForm()}}
        modalSize="small"
        modalTitle={"Editando " + client.name}
        btnText="Editar"
        displayActionBar
      >
        <Toast
          refValue={refValue}
          errors={toastErrors}
          type={messageType}
          icon={messageType == "success" ? "fa-check" : "fa-exclamation-triangle"}
          style={{ position: 'initial', margin: 10 }}
        />
        <div>
          <div className="simple-modal-container">
            <Section
              className="mb-16"
              title="Informações da fazenda"
              subtitle="Edite os dados os clientes"
            >
              <div className="card">
                <div className="admin-modal">
                  <div className="mb-16">
                    <span className="title block mb-4">Nome de Exibição</span>
                    <TextInput
                      name="edit-name"
                      style={{ width: "190px" }}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="mb-16">
                    <span className="title block mb-4">Telefone</span>
                    <TextInput
                      name="edit-phone"
                      style={{ width: "138px" }}
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                  <div className="mb-16">
                    <span className="title block mb-4">Logotipo</span>
                    <label htmlFor="edit-avatar-image" className="farm-avatar">
                      <Avatar
                        text=""
                        size="80"
                        img={logo || client.logo_url || ""}
                        icon="fa-camera"
                      />
                      <span className="farm-avatar-label">
                        <span className="subtitle">
                          Escolha arquivos .png ou .jpg com pelo menos 200x200px
                          para o tamanho da imagem.
                        </span>
                      </span>
                      <input
                        id="edit-avatar-image"
                        name="edit-avatar"
                        type="file"
                        style={{ display: "none" }}
                        accept="image/*"
                        onChange={setChangeFile}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </Section>
            <Section
              className="mb-16 mt-16"
              title="Informações de localização"
              subtitle="Edite os dados de localização"
            >
              <div className="card">
                <div className="admin-modal">
                  <div className="mb-16">
                    <span className="title block mb-4">Cidade</span>
                    <TextInput
                      name="edit-city"
                      style={{ width: "190px" }}
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </div>
                  <div className="mb-16">
                    <span className="title block mb-4">Estado</span>
                    <TextInput
                      name="edit-state"
                      style={{ width: "190px" }}
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                    />
                  </div>
                  <div className="mb-16">
                    <span className="title block mb-4">País</span>
                    <TextInput
                      name="edit-county"
                      style={{ width: "190px" }}
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    />
                  </div>
                  <div className="mb-16">
                    <span className="title block mb-4">Longitude</span>
                    <TextInput
                      name="edit-longitude"
                      style={{ width: "190px" }}
                      value={longitude}
                      onChange={(e) => setLongitude(e.target.value)}
                    />
                  </div>
                  <div>
                    <span className="title block mb-4">Latitude</span>
                    <TextInput
                      name="edit-latitude"
                      style={{ width: "190px" }}
                      value={latitude}
                      onChange={(e) => setLatitude(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </Section>
          </div>
        </div>
      </Modal>
    </form>
  )
}

export default EditModal;
