import React from 'react';
import { Link } from 'react-router-dom';

import SVGIcon from '../../services/SVGIcon';
import { Logo } from '../../components';

const Header = () => {
  return (
    <div className="top-header">
      <section className="full-header">
        <div className="header-filter">
          <Logo>
            <SVGIcon
              width="149"
              height="48"
              name={'farmbox-logo-with-writes'}
              fill={'none'} />
          </Logo>
        </div>
      </section>

      <div className="action-links">
        <Link to="/front/admin">Clientes</Link>
        <Link to="/front/admin/users">Usuários</Link>
        <span> — </span>
        <a href="/users/sign_out">Sair</a>
      </div>
    </div>
  )
}

export default Header;
