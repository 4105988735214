import React from 'react';

const ListClients = ({clients}) => {
  return (
    <div className="clients-list">
      {clients && clients.length > 0 && clients.map((client, key) => (
        <span key={key}>{client.name}</span>
      ))}
    </div>
  )
}

export default ListClients;
