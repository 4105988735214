import React, { useState, useEffect, useRef } from 'react';

import { getClients } from '../../services/admin'
import { createClient, editClient } from '../../services/admin'

import { Button, Loader } from '@checkplant/soil'
import Header from './Header';
import { scrollSmoothHandler } from '../../helpers/Toast'

import EditClient from './components/editClient'
import CreateClient from './components/createClient'

const Admin = () => {
  const [clients, setClients] = useState([{}, {}, {}, {}]);
  const [loading, setLoading] = useState(true);
  const [clientToEdit, setClientToEdit] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(window.location.hash === "#createModal");

  const [toastMessages, setToastMessages] = useState([]);
  const [toastType, setToastType] = useState('error');
  const [tempPassword, setTempPassword] = useState('');
  const scrollEditDiv = useRef();
  const scrollCreateDiv = useRef();

  useEffect(() => {
    loadClients();
  }, [])

  useEffect(() => {
    var timer = setTimeout(() => {
      setToastMessages([])
    }, 5000)

    return () => clearTimeout(timer)
  }, [toastMessages])

  const loadClients = () => {
    getClients().then(response => {
      setClients(response.data)
      setLoading(false)
    })
  }

  const onCreate = (client, callback) => {
    setLoading(true);

    createClient({ client }).then((res) => {
      setToastMessages(['Criado com sucesso.']);
      setToastType('success');
      scrollSmoothHandler(scrollCreateDiv);
      setTempPassword(res.data.tmp_password);
      setLoading(false);
    }).catch((error) => {
      setToastMessages(error.response.data);
      setToastType('error');
      scrollSmoothHandler(scrollCreateDiv);
      setLoading(false);
    })
  }

  const onEdit = (client, uuid) => {
    setLoading(true)

    editClient({client}, uuid).then((res) => {
      setToastMessages(['Editado com sucesso.'])
      scrollSmoothHandler(scrollEditDiv)
      setToastType('success')
      loadClients()

      setTimeout(() => {
        closeEditModal();
      }, 1000)
    }).catch(error => {
      setToastType('error');
      setToastMessages(error.response.data)
      scrollSmoothHandler(scrollEditDiv)
      setLoading(false)
    })
  }

  const closeCreateModal = () => {
    setToastMessages([]);
    setShowCreateModal(false);
    setTempPassword('');
    loadClients();
  }

  const closeEditModal = () => {
    setToastMessages([]);
    setClientToEdit({})
    setShowEditModal(false);
  }

  const onOpenClientEdit = client => {
    setShowEditModal(true);
    setClientToEdit(client)
  }

  const onClose = () => {
    setToastMessages([]);
    setClientToEdit({})
  }

  return (
    <>
      <div className="admin-screen">
        <Header />
        <div className="admin-container">
          <div className="section-header">
            <Button
              children="Adicionar Cliente"
              theme="primary"
              onClick={() => setShowCreateModal(true)}
            />
          </div>
          <div className="table-container">
            <table className="table table-striped font-type font-size-6 regular" border="0">
              <thead>
                <tr className="font-type font-size-8 bold">
                  <th><span>Estado</span></th>
                  <th><span>Nome</span></th>
                  <th><span>Telefone</span></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {clients.map((client, index) => (
                  <tr key={index}>
                    <td>{client.state}</td>
                    <td>{client.name}</td>
                    <td>{client.phone}</td>
                    <td>
                      {client.uuid &&
                      (<Button
                        children="Editar"
                        rightIcon="fa-chevron-right"
                        theme="minor"
                        onClick={() => onOpenClientEdit(client)}
                      />)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Loader isLoading={loading} />
      <CreateClient
        showModal={showCreateModal}
        setClose={setShowCreateModal}
        refValue={scrollCreateDiv}
        toastErrors={toastMessages}
        createClient={onCreate}
        tempPassword={tempPassword}
        messageType={toastType}
        onClose={closeCreateModal}
      />
      <EditClient
        client={clientToEdit}
        showModal={showEditModal}
        setClose={setShowEditModal}
        refValue={scrollEditDiv}
        toastErrors={toastMessages}
        editClient={onEdit}
        messageType={toastType}
        onClose={onClose}
      />
    </>
  )
}

export default Admin;
