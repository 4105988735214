import { CHANGE_USER } from './types';

const initialState = {
  currentUser: null,
};

const mainReducer = (state, action) => {
  switch (action.type) {
    case CHANGE_USER:
      return { ...state, currentUser: action.payload };

    default:
      return state;
  }
};

export {
  initialState,
  mainReducer,
};
