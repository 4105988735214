import api from '../configs/api';
import qs from 'qs';

export const getClients = (params) => (
  api.get('/admin/clients.json', params)
);

export const createClient = (client) => (
  api.post('/admin/clients.json', client)
)

export const editClient = (client, uuid) => (
  api.put('/admin/clients/' + uuid, client)
)

export const getUsers = (filters) => (
  api.get(`/admin/users.json?` + qs.stringify(filters))
);

export const updateUser = (uuid, params) => (
  api.put(`/admin/users/${uuid}.json`, params)
);