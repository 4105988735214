import React, { useState } from 'react';

import { Modal, ModalPrompt, Button, Toast, TextInput, Avatar, Section } from '@checkplant/soil';

const CreateModal = ({
  showModal,
  setClose,
  refValue,
  toastErrors,
  createClient,
  messageType,
  tempPassword,
  onClose
}) => {
  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [logo, setLogo] = useState('');
  const [state, setState] = useState('');
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');

  const [email, setEmail] = useState('');
  const [nameUser, setNameUser] = useState('');
  const [username, setUsername] = useState('');
  const [copiedPassword, setCopiedPassword] = useState('');

  const onKeyDown = keyEvent => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  const setChangeFile = (e) => {
    let { files } = e.target;

    let reader = new FileReader();
    reader.onloadend = function () {
      setLogo(reader.result)
    }
    reader.readAsDataURL(files[0]);
  }

  const closeModal = () => {
    clearForm();
    setClose(false);
  };

  const copyTempPassword = () => {
    var textField = document.createElement('textarea')
    textField.innerText = tempPassword;
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()

    setCopiedPassword(true)
  }

  const clearForm = () => {
    setName('');
    setCity('');
    setLogo('');
    setState('');
    setPhone('');
    setCountry('');
    setLatitude('');
    setLongitude('');
    setEmail('');
    setNameUser('');
    setUsername('');
    setCopiedPassword(false);

    onClose();
  }

  const onSubmit = e => {
    e.preventDefault();

    var b64 = logo.replace(/^data:.+;base64,/, '');

    var client = {
      name, city, state, country, phone, latitude, longitude, logo: b64,
      users_attributes: [
        {
          name: nameUser, username, email
        }
      ]
    }

    createClient(client, clearForm);
  }

  return (
    <form onKeyDown={onKeyDown} onSubmit={onSubmit}>
      <Modal
        showModal={showModal}
        onClose={closeModal}
        modalSize="small"
        modalTitle="Adicionar novo cliente"
        btnClass="primary"
        btnText="Adicionar"
        btnType="submit"
        displayActionBar
      >
        <Toast
          refValue={refValue}
          errors={toastErrors}
          type={messageType}
          icon={messageType == "success" ? "fa-check" : "fa-exclamation-triangle"}
          style={{ position: 'initial', margin: 10 }}
        />
        <div>
          <div className="simple-modal-container">
            <Section
              title="Informações do cliente"
              subtitle="Preencha os dados para cadastrar um novo cliente"
            >
              <div className="card">
                <div className="admin-modal">
                  <div className="mb-16">
                    <span className="title block mb-4">Nome de Exibição</span>
                    <TextInput
                      name="name"
                      style={{ width: "190px" }}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="mb-16">
                    <span className="title block mb-4">Telefone</span>
                    <TextInput
                      name="phone"
                      style={{ width: "138px" }}
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                  <div>
                    <span className="title block mb-4">Logotipo</span>
                    <label
                      htmlFor="create-avatar-image"
                      className="farm-avatar"
                    >
                      <Avatar text="" size="80" img={logo} icon="fa-camera" />
                      <span className="farm-avatar-label">
                        <span className="subtitle">Escolha arquivos .png ou .jpg com pelo menos 200x200px para o tamanho da imagem.</span>
                      </span>
                      <input
                        id="create-avatar-image"
                        name="avatar"
                        type="file"
                        style={{ display: "none" }}
                        accept="image/*"
                        onChange={setChangeFile}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </Section>
            <Section
              title="Informações de localização"
              subtitle="Preencha com os dados de localização"
              className="mb-16 mt-16"
            >
              <div className="card">
                <div className="admin-modal">
                  <div className="mb-16">
                    <span className="title block mb-4">Cidade</span>
                    <TextInput
                      name="city"
                      style={{ width: "190px" }}
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </div>
                  <div className="mb-16">
                    <span className="title block mb-4">Estado</span>
                    <TextInput
                      name="state"
                      style={{ width: "190px" }}
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                    />
                  </div>
                  <div className="mb-16">
                    <span className="title block mb-4">País</span>
                    <TextInput
                      name="country"
                      style={{ width: "190px" }}
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    />
                  </div>
                  <div className="mb-16">
                    <span className="title block mb-4">Longitude</span>
                    <TextInput
                      name="longitude"
                      style={{ width: "190px" }}
                      value={longitude}
                      onChange={(e) => setLongitude(e.target.value)}
                    />
                  </div>
                  <div>
                    <span className="title block mb-4">Latitude</span>
                    <TextInput
                      name="latitude"
                      style={{ width: "190px" }}
                      value={latitude}
                      onChange={(e) => setLatitude(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </Section>
            <Section
              title="Informações de usuário"
              subtitle="Preencha com os dados do proprietário"
              className="mb-16 mt-16"
            >
              <div className="card">
                <div className="admin-modal">
                  <div className="mb-16">
                    <span className="title block mb-4">Nome</span>
                    <TextInput
                      name="nameUser"
                      style={{ width: "190px" }}
                      value={nameUser}
                      onChange={(e) => setNameUser(e.target.value)}
                    />
                  </div>
                  <div className="mb-16">
                    <span className="title block mb-4">Username</span>
                    <TextInput
                      name="username"
                      style={{ width: "190px" }}
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  <div className="mb-16">
                    <span className="title block mb-4">Email</span>
                    <TextInput
                      name="email"
                      style={{ width: "190px" }}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </Section>
          </div>
          <ModalPrompt
            showModal={tempPassword ? true : false}
            btnText="Entendi"
            modalTitle="Usuário Cadastrado"
            onClose={closeModal}
            onButtonClick={closeModal}
          >
            <div className="card">
              <div className="group">
                <span className="title">Usuário</span>
                <h1 className="title">{username}</h1>
              </div>
              <div className="group">
                <span className="title">Senha temporária</span>
                <div className="flex-box">
                  <h1 className="title">{tempPassword}</h1>
                  <Button leftIcon={copiedPassword ? "fa-check" : "fa-copy"}
                    children={copiedPassword ? "COPIADO" : "COPIAR"}
                    theme="minor"
                    onClick={copyTempPassword}
                  />
                </div>
              </div>
            </div>
            <div className="simple-toast warning" style={{position: 'initial'}}>
              <h4>Anote essas informações!</h4>
              <p>
                Para entrar com esse usuário você precisa da senha temporária.
              </p>
            </div>
          </ModalPrompt>
        </div>
      </Modal>
    </form>
  )
}

export default CreateModal;
