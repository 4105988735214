import React, { useState, useEffect, useRef } from 'react';

import { getClients, getUsers, updateUser } from '../../services/admin'

import { Loader, Pagination, Select, TextInput } from '@checkplant/soil'
import ListClients from './components/listClients'
import EditUserModal from './components/editUser'
import { scrollSmoothHandler } from '../../helpers/Toast'

import Header from './Header'

const Users = () => {
  const [name, setName] = useState('');
  const [meta, setMeta] = useState({})
  const [users, setUsers] = useState([{}, {}, {}, {}])
  const [errors, setErrors] = useState([]);
  const [client, setClient] = useState('all');
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [toastType, setToastType] = useState('error');
  const [userModal, setUserModal] = useState({});
  const [selectedPage, setSelectedPage] = useState(0);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const editErrors = useRef();

  useEffect(() => {
    loadClients();
  }, [])

  useEffect(() => {
    loadUsers();
  }, [name, client, selectedPage])

  const loadUsers = () => {
    setLoading(true);

    var filters = {
      page: selectedPage + 1,
      filters: {}
    }

    if (name.length) {
      filters.filters.by_name = name
    }

    if (client != "all") {
      filters.filters.by_client_uuid = client
    }

    getUsers(filters).then(response => {
      setMeta(response.data.meta)
      setUsers(response.data.users)
      setLoading(false)
    })
  }

  const loadClients = () => {
    getClients().then(response => {
      var options = [{label: 'Selecione um Cliente', value: "all"}];
      setClient(options[0])

      for(var client of response.data) {
        options.push({label: client.name, value: client.uuid});
      }

      setClients(options)
    })
  }

  const clearInputName = () => {
    setName('');
  }

  // const openModalEdit = (user) => {
  //   setUserModal(user);
  //   setShowModalEdit(true);
  // };

  const onClose = () => {
    setUserModal({})
    setErrors([])
    setToastType('error')
  }

  const closeModal = () => {
    setUserModal({});
    setErrors([])
    setShowModalEdit(false);
  }

  const onEdit = (user, uuid) => {
    setLoading(true);

    updateUser(uuid, {user}).then(response => {
      setErrors(['Editado com sucesso.'])
      setToastType('success')
      scrollSmoothHandler(editErrors)
      loadUsers();

      setTimeout(() => {
        closeModal();
      }, 1000)
    }).catch(error => {
      setErrors(error.response.data)
      setToastType('error')
      scrollSmoothHandler(editErrors)
      setLoading(false)
    })
  }

  return (
    <>
      <div className="admin-screen">
        <Header />
        <div className="admin-container">
          <div className="section-header">
            <TextInput
              id="search-name"
              value={name}
              onClickRight={clearInputName}
              onChange={e => setName(e.target.value)}
              placeholder="Pesquise pelo nome"
              leftIcon="fa-magnifying-glass"
              rightIcon="fa-xmark"
              debounce={ 500 }
            />
            <Select
              value={client}
              options={clients}
              onChange={(e) => setClient(e.target.value)}
              placeholder="Selecione um cliente"
              style={{ width: "300px" }}
              searchable
            />
          </div>
          <div className="table-container">
            <table className="table table-striped font-type font-size-6 regular" border="0">
              <thead>
                <tr className="font-type font-size-8 bold">
                  <th><span>Nome</span></th>
                  <th><span>Username</span></th>
                  <th><span>Email</span></th>
                  <th><span>Cliente(s)</span></th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, key) => (
                  <tr key={key}>
                    <td>{user.name}</td>
                    <td>{user.username}</td>
                    <td>{user.email}</td>
                    <td><ListClients clients={user.clients} /></td>
                  </tr>
                ))}
              </tbody>
            </table>
            {meta.total_pages && meta.total_pages > 0 && (
              <Pagination
                className="pagination"
                pageCount={meta.total_pages}
                onPageChange={data => setSelectedPage(data.selected)}
              />
              )}
          </div>
        </div>
      </div>
      <Loader isLoading={loading} />
      <EditUserModal
        user={userModal}
        refValue={editErrors}
        showModal={showModalEdit}
        setClose={setShowModalEdit}
        toastErrors={errors}
        messageType={toastType}
        onClose={onClose}
        editUser={onEdit}
      />
    </>
  )
}

export default Users;
