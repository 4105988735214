import React from 'react'
import { Route } from 'react-router-dom'

import Login from './screens/login'
import SignUp from './screens/sign_up'
import Edit from './screens/edit'
import Password from './screens/password'
import Recovered from './screens/recovered'
import AdminClients from './screens/admin'
import AdminUsers from './screens/admin/Users'
import Admin from './screens/admin/admin'
import Page403 from './screens/error/403';

const Routes = () => {
  return (
    <>
      <Route exact path="/front/" children={<Login />} />
      <Route exact path="/front/sign_up" children={<SignUp />} />
      <Route exact path="/front/users/edit/" children={<Edit />} />
      <Route exact path="/front/password" children={<Password />} />
      <Route exact path="/front/recovered" children={<Recovered />} />

      <Route exact path="/front/admin">
        <Admin component={<AdminClients />} />
      </Route>

      <Route exact path="/front/admin/users">
        <Admin component={<AdminUsers />} />
      </Route>

      <Route exact path="/front/403" children={<Page403 />} />
    </>
  )
}

export default Routes;