import React, { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import styled from 'styled-components'
import SVGIcon from '../../services/SVGIcon';
import { Button, TextInput, Toast, Content } from '@checkplant/soil'
import { login } from '../../services/user'

import { Footer, Card, Logo } from '../../components';

const Login = () => {
  const history = useHistory();
  const containerDiv = useRef();

  const [visible, setVisible] = useState(false);
  const [user, setUser] = useState(null);
  const [error, setError] = useState([]);
  const onVisiblePassword = () => setVisible(!visible);
  const onHandleChange = e => setUser({ ...user, [e.target.name]: e.target.value })

  const returnURL = new URLSearchParams(window.location.search).get('return_to');
  const signUpFlow = new URLSearchParams(window.location.search).get('sign_up')

  const passwordRecovered = e => {
    e.preventDefault();
    history.push('/front/password')
  }

  const goToSignUp = () => {
    history.push(`/front/sign_up?return_to=${encodeURIComponent(returnURL)}`)
  }

  const onSubmit = e => {
    e.preventDefault();

    let returnURL = new URLSearchParams(window.location.search).get('return_to');

    user.returnURL = returnURL;

    login({ user }).then((resp) => {
      if (resp.data) {
        if (returnURL) {
          window.location.href = returnURL;
        } else {
          window.location.reload();
        }
      } else {
        setError(["Usuário e/ou senha inválido(s)"])
      }
    }).catch(() => setError(["Usuário e/ou senha inválido(s)"]))
  }

  return (
    <>
      <Content>
        <Card>
          <Logo>
            <SVGIcon
              width="149"
              height="48"
              name={'farmbox-logo-with-writes'}
              fill={'none'} />
          </Logo>
          <form onSubmit={onSubmit}>
            <Toast
              refValue={containerDiv}
              errors={error}
              style={{ position: "initial", marginBottom: 10, fontSize: 14 }}
            />
            <TextInput
              name="login"
              label="Usuário"
              type="text"
              onChange={onHandleChange}
              value={user ? user.login : ''}
              style={{ marginTop: '16px' }} />
            <TextInput
              name="password"
              label="Senha"
              value={user ? user.password : ''}
              type={visible ? 'text' : 'password'}
              rightIcon={visible ? 'fa-eye-slash' : 'fa-eye'}
              style={{ marginTop: '20px', marginBottom: '5px' }}
              onChange={onHandleChange}
              onClickRight={onVisiblePassword} />

            <ButtonOutlined
              theme="minor"
              type="button"
              children="Esqueci a senha"
              onClick={passwordRecovered} />
            <ButtonSubmit theme="primary" type="submit" children="Entrar" />

            {signUpFlow && 
              <div style={{ textAlign: 'center' }}>
                <hr />
                 Ainda não possui uma conta Farmbox?

                 <ButtonOutlined
                  type="button"
                  children="Criar conta"
                  style={{display: 'inline-block'}}
                  onClick={goToSignUp} />
              </div>
            }
          </form>
        </Card>
      </Content>
      <Footer>
        <SVGIcon name={'farmbox-logo-footer'} fill={'none'} width="80" height="53" />
      </Footer>
    </>
  )
}

const ButtonSubmit = styled(Button)`
  width: 100%;
  margin-top: 24px;

  display: center;
  justify-content: center;
`;

const ButtonOutlined = styled(Button)`
  padding: 8px 0px 0px 0px;
  margin-bottom: 24px;
`;

export default Login;
