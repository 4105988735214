import React, { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import SVGIcon from '../../services/SVGIcon';
import { Button, TextInput, Toast, Content } from '@checkplant/soil'
import { signUp } from '../../services/user'

import { Footer, Card, Logo } from '../../components';

const SignUp = () => {
  const UNEXPECTED_ERROR = "Houve um erro inesperado";
  const history = useHistory();
  const containerDiv = useRef();

  const [visiblePassword, setVisiblePassword] = useState(false);
  const [visiblePasswordConfirmation, setVisiblePasswordConfirmation] = useState(false);
  const [user, setUser] = useState(null);
  const [error, setError] = useState([]);

  const onVisiblePassword = () => setVisiblePassword(!visiblePassword);
  const onVisiblePasswordConfirmation = () => setVisiblePasswordConfirmation(!visiblePasswordConfirmation);

  const onHandleChange = e => setUser({ ...user, [e.target.name]: e.target.value });

  const returnURL = new URLSearchParams(window.location.search).get('return_to');

  // const goToLogin = () => history.push(`/front/login?return_to=${returnURL}`);

  const onSubmit = e => {
    e.preventDefault();

    signUp({ user }).then((response) => {
      if (response.data) {
        // TODO: deal with errors
        if (returnURL) {
          window.location.href = returnURL;
        }
      } else {
        setError(UNEXPECTED_ERROR);
      }
    }).catch((err) => {
      if (err.response.status == 422) {
        const { errors } = err.response.data;

        setError(errors);
      } else {
        setError(UNEXPECTED_ERROR);
      }
    })
  }

  return (
    <>
      <Content>
        <Card>
          <Logo>
            <SVGIcon
              width="149"
              height="48"
              name={'farmbox-logo-with-writes'}
              fill={'none'} />
          </Logo>
          <form onSubmit={onSubmit}>
            <Toast
              refValue={containerDiv}
              errors={error}
              style={{ position: "initial", marginBottom: 10, fontSize: '12px' }}
            />
            <TextInput
              name="username"
              label="Usuário"
              type="text"
              onChange={onHandleChange}
              value={user ? user.login : ''}
              style={{ marginTop: '16px' }} />
            <TextInput
              name="name"
              label="Nome"
              type="text"
              onChange={onHandleChange}
              value={user ? user.name : ''}
              style={{ marginTop: '16px' }} />
            <TextInput
              name="email"
              label="Email"
              type="email"
              onChange={onHandleChange}
              value={user ? user.email : ''}
              style={{ marginTop: '16px' }} />
            <TextInput
              name="phone"
              label="Telefone"
              type="text"
              onChange={onHandleChange}
              value={user ? user.phone : ''}
              style={{ marginTop: '16px' }} />
            <TextInput
              name="password"
              label="Senha"
              value={user ? user.password : ''}
              type={visiblePassword ? 'text' : 'password'}
              rightIcon={visiblePassword ? 'fa-eye-slash' : 'fa-eye'}
              style={{ marginTop: '20px', marginBottom: '5px' }}
              onChange={onHandleChange}
              onClickRight={onVisiblePassword} />

            <TextInput
              name="password_confirmation"
              label="Confirmação de Senha"
              value={user ? user.password_confirmation : ""}
              type={visiblePasswordConfirmation ? "text" : "password"}
              rightIcon={
                visiblePasswordConfirmation ? "fa-eye-slash" : "fa-eye"
              }
              style={{ marginTop: "20px", marginBottom: "5px" }}
              onChange={onHandleChange}
              onClickRight={onVisiblePasswordConfirmation}
            />

            <Button theme="primary" children="Criar Conta" btnType="submit" />
          </form>
        </Card>
      </Content>
      <Footer>
        <SVGIcon name={'farmbox-logo-footer'} fill={'none'} width="80" height="53" />
      </Footer>
    </>
  );
}

export default SignUp;
