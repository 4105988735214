import React, { useState, useEffect } from 'react';

import { Modal, Toast, TextInput, Avatar, Button } from '@checkplant/soil';

const EditModal = ({
  showModal, setClose, user, refValue, toastErrors, messageType, onClose, editUser
}) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [avatar, setAvatar] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [password_confirmation, setPasswordConfirmation] = useState('');

  useEffect(() => {
    setName(user.name);
    setEmail(user.email);
    setPhone(user.phone);
    setUsername(user.username);
  }, [user])

  const onKeyDown = keyEvent => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) { 
      keyEvent.preventDefault();
    }
  };

  const onSubmit = e => {
    e.preventDefault();

    var newUser = {
      name, email, phone, username
    }

    if (avatar && avatar != '') {
      var b64 = avatar.replace(/^data:.+;base64,/, '');

      newUser = { ...newUser, avatar: b64 }
    }

    if(password && password_confirmation) {
      newUser = { ...newUser, password, password_confirmation }
    }

    editUser(newUser, user.uuid)
  }

  const clearForm = () => {
    setName('');
    setEmail('');
    setPhone('');
    setAvatar('');
    setUsername('');
    setPassword('');
    setPasswordConfirmation('');

    onClose();
  }

  const setChangeFile = (e) => {
    let { files } = e.target;

    let reader = new FileReader();
    reader.onloadend = function () {
      setAvatar(reader.result)
    }
    reader.readAsDataURL(files[0]);
  }

  return (
    <form onKeyDown={onKeyDown} onSubmit={onSubmit}>
      <Modal
        showModal={showModal}
        onClose={() => {setClose(false); clearForm()}}
        modalSize="small"
        modalTitle={"Editando " + user.name}
        btnText="Editar"
        btnType="submit"
        displayActionBar
      >
        <Toast
          refValue={refValue}
          errors={toastErrors}
          type={messageType}
          icon={messageType == "success" ? "fa-check" : "fa-exclamation-triangle"}
          style={{ position: 'initial', margin: 10 }}
        />
        <div>
          <div className="simple-modal-container">
            <div className="title-section mb-16">
              <span className="internal-section-title">Informações do Usuário</span>
              <span className="internal-section-subtitle">Edite os dados do usuário</span>
            </div>
            <div className="card">
              <div className="admin-modal">
                <div className="mb-16">
                  <span className="title block mb-4">Nome Completo</span>
                  <TextInput
                    type="text"
                    name="name"
                    style={{ width: '250px' }}
                    value={name} 
                    onChange={e => setName(e.target.value)}
                    />
                </div>
                <div className="mb-16">
                  <span className="title block mb-4">Username</span>
                  <TextInput
                    type="text"
                    name="username"
                    style={{ width: '250px' }}
                    value={username} 
                    onChange={e => setUsername(e.target.value)}
                    />
                </div>
                <div className="mb-16">
                  <span className="title block mb-4">Email</span>
                  <TextInput
                    type="text"
                    name="email"
                    style={{ width: '250px' }}
                    value={email} 
                    onChange={e => setEmail(e.target.value)}
                    />
                </div>
                <div className="mb-16">
                  <span className="title block mb-4">Telefone</span>
                  <TextInput
                    type="text"
                    name="phone"
                    style={{ width: '250px' }}
                    value={phone} 
                    onChange={e => setPhone(e.target.value)}
                    />
                </div>
                <div>
                  <span className="title block mb-4">Avatar</span>
                  <label htmlFor="edit-avatar-image" className="farm-avatar">
                    <Avatar 
                      text={user.initials}
                      size="80"
                      img={avatar || user.avatar_url || ''}
                    />
                    <span className="farm-avatar-label">
                      <span className="subtitle">Escolha arquivos .png ou .jpg com pelo menos 200x200px para o tamanho da imagem.</span>
                    </span>
                    <input 
                      id="edit-avatar-image"
                      name="edit-avatar"
                      type="file"
                      style={{ display: 'none'}}
                      accept="image/*"
                      onChange={setChangeFile}
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="title-section mb-16 mt-16">
              <span className="internal-section-title">Informações de Segurança</span>
              <span className="internal-section-subtitle">Defina uma nova senha</span>
            </div>
            <div className="card">
              <div className="admin-modal">
                <div className="mb-16">
                  <span className="title block mb-4">Senha</span>
                  <TextInput
                    type="password"
                    name="password"
                    style={{ width: '190px' }}
                    value={password} 
                    onChange={e => setPassword(e.target.value)}
                    />
                </div>
                <div>
                  <span className="title block mb-4">Confirmar Senha</span>
                  <TextInput
                    type="password"
                    name="password_confirmation"
                    style={{ width: '190px'}}
                    value={password_confirmation} 
                    onChange={e => setPasswordConfirmation(e.target.value)}
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </form>
  )
}

export default EditModal;
