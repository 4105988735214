import React from "react";

const Page403 = () => {
  return (
    <div class="container">
      <div class="row">
        <div class="col-md-2 text-center">
          <p><i class="fa fa-exclamation-triangle fa-5x"></i></p>
        </div>
        <div class="col-md-10">
          <h3>Acesso Negado!</h3>
          <p>Este usuário não possui permissão para acessar esta página.</p>
        </div>
      </div>
    </div>
  );
};

export default Page403;