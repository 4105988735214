import React, { useState } from 'react';

import { Button, TextInput } from '@checkplant/soil';

const AccessPrompt = (props) => {
  const [password, setPassword] = useState('');
  const [visible, setVisible] = useState(false);

  const onHandleChange = (e) => ( setPassword(e.target.value) )
  const onVisiblePassword = () => ( setVisible(!visible) )

  const submit = () => {
    props.onSubmit(password);
  }

  return (
    <div>
      <div className="mb-24 flex justify-content-center">
        <span className="font-soil h2">Editar Acesso</span>
      </div>

      <span>
        <TextInput
          name="password"
          label="Senha atual"
          value={password}
          placeholder="******"
          type={visible ? 'text' : 'password'}
          leftIcon="fa-key"
          rightIcon={visible ? 'fa-eye-slash' : 'fa-eye'}
          errorMessage={props.errors ? 'Senha incorreta.' : ''}
          style={{ marginTop: '16px', marginBottom: '16px', fontSize: '12px' }}
          onChange={onHandleChange}
          onClickRight={onVisiblePassword} />
      </span>

      <div className="flex justify-content-center">
        <Button
          className="mb-8"
          theme="regular"
          children="Confirmar"
          onClick={submit} />
      </div>
      <div className="flex justify-content-center">
        <Button
          theme="outline"
          children="Cancelar"
          onClick={props.onCancel} />
      </div>
    </div>
  );
};

export default AccessPrompt
