import React, { useEffect, useState } from 'react';

import { TextInput } from '@checkplant/soil';

const EditAccess = (props) => {
  const [user, setUser] = useState({ ...props.user })
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordConfirmationVisible, setPasswordConfirmationVisible] = useState(false);

  const onHandleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    update(user);
  }, [user])

  const update = () => {
    props.onUpdate(user);
  }

  return (
    <div>
      <span>
        <TextInput
          name="password"
          label="Nova senha"
          value={user.password}
          placeholder="******"
          type={passwordVisible ? 'text' : 'password'}
          rightIcon={passwordVisible ? 'fa-eye-slash' : 'fa-eye'}
          style={{ marginTop: '16px', marginBottom: '8px', fontSize: '12px' }}
          onChange={onHandleChange}
          onClickRight={() => setPasswordVisible(!passwordVisible)} />
      </span>
      <span>
        <TextInput
          name="passwordConfirmation"
          label="Confirmação de nova Senha"
          value={user.passwordConfirmation}
          placeholder="******"
          type={passwordConfirmationVisible ? 'text' : 'password'}
          rightIcon={passwordConfirmationVisible ? 'fa-eye-slash' : 'fa-eye'}
          style={{ marginTop: '16px', marginBottom: '8px', fontSize: '12px' }}
          onChange={onHandleChange}
          onClickRight={() => setPasswordConfirmationVisible(!passwordConfirmationVisible)} />
      </span>
    </div>
  );
};

export default EditAccess
